import React from 'react';
import '../styles/edition-2024.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const Edition2024 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="circles">
        <StaticImage
          className="e2024-circle-right"
          src="../../../images/future-academy/circle-right-e2024.png"
          alt="Circle right"
          placeholder="none"
          loading="lazy"
        />
        <div className="e2024-ctn">
          <div className="container">
            <h3>{t`future-academy.e2024-title`}</h3>
            <h2>{t`future-academy.e2024-title2`}</h2>
            <div className="e2024-con">
              <div className="e2024-con1">
                <div className="inside-con1">
                  <svg
                    className="e2024-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="49"
                    height="48"
                    viewBox="0 0 49 48"
                    fill="none">
                    <g clip-path="url(#clip0_815_318)">
                      <path
                        d="M18.5 22C18.5 23.5913 19.1321 25.1174 20.2574 26.2426C21.3826 27.3679 22.9087 28 24.5 28C26.0913 28 27.6174 27.3679 28.7426 26.2426C29.8679 25.1174 30.5 23.5913 30.5 22C30.5 20.4087 29.8679 18.8826 28.7426 17.7574C27.6174 16.6321 26.0913 16 24.5 16C22.9087 16 21.3826 16.6321 20.2574 17.7574C19.1321 18.8826 18.5 20.4087 18.5 22Z"
                        stroke="#52368C"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M35.814 33.314L27.328 41.8C26.578 42.5493 25.5612 42.9701 24.501 42.9701C23.4408 42.9701 22.424 42.5493 21.674 41.8L13.186 33.314C10.9484 31.0763 9.42468 28.2254 8.80738 25.1217C8.19009 22.018 8.50699 18.801 9.71801 15.8775C10.929 12.9539 12.9798 10.4551 15.611 8.69701C18.2421 6.93894 21.3355 6.00058 24.5 6.00058C27.6645 6.00058 30.7579 6.93894 33.389 8.69701C36.0202 10.4551 38.071 12.9539 39.282 15.8775C40.493 18.801 40.8099 22.018 40.1926 25.1217C39.5753 28.2254 38.0516 31.0763 35.814 33.314Z"
                        stroke="#52368C"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_815_318">
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="inside-con2">
                  <h3>{t`future-academy.e2024-title-con1`}</h3>
                  <div className="con2-grid">
                    <div>
                      <h4>{t`future-academy.e2024-title2-con1`}</h4>
                      <p className="p-body">{t`future-academy.e2024-p2-con1`}</p>
                      <StaticImage
                        src="../../../images/future-academy/uw-fa.png"
                        alt="Circle right"
                        placeholder="none"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h4>{t`future-academy.e2024-title3-con1`}</h4>
                      <p className="p-body">{t`future-academy.e2024-p3-con1`}</p>
                      <StaticImage
                        src="../../../images/future-academy/aip-fa.png"
                        alt="Circle right"
                        placeholder="none"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <h4>{t`future-academy.e2024-title4-con1`}</h4>
                      <p className="p-body">{t`future-academy.e2024-p4-con1`}</p>
                      <StaticImage
                        src="../../../images/future-academy/agh-fa.png"
                        alt="Circle right"
                        placeholder="none"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="e2024-con2">
                <div className="inside-con1">
                  <svg
                    className="e2024-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="49"
                    height="48"
                    viewBox="0 0 49 48"
                    fill="none">
                    <g clip-path="url(#clip0_815_247)">
                      <path
                        d="M10.5 14C10.5 16.1217 11.3429 18.1566 12.8431 19.6569C14.3434 21.1571 16.3783 22 18.5 22C20.6217 22 22.6566 21.1571 24.1569 19.6569C25.6571 18.1566 26.5 16.1217 26.5 14C26.5 11.8783 25.6571 9.84344 24.1569 8.34315C22.6566 6.84285 20.6217 6 18.5 6C16.3783 6 14.3434 6.84285 12.8431 8.34315C11.3429 9.84344 10.5 11.8783 10.5 14Z"
                        stroke="#52368C"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.5 42V38C6.5 35.8783 7.34285 33.8434 8.84315 32.3431C10.3434 30.8429 12.3783 30 14.5 30H22.5C24.6217 30 26.6566 30.8429 28.1569 32.3431C29.6571 33.8434 30.5 35.8783 30.5 38V42"
                        stroke="#52368C"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M32.5 6.25999C34.2208 6.7006 35.7461 7.7014 36.8353 9.10462C37.9245 10.5078 38.5157 12.2337 38.5157 14.01C38.5157 15.7863 37.9245 17.5122 36.8353 18.9154C35.7461 20.3186 34.2208 21.3194 32.5 21.76"
                        stroke="#52368C"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M42.5 42V38C42.4898 36.2343 41.8958 34.5216 40.8105 33.1288C39.7252 31.736 38.2097 30.7414 36.5 30.3"
                        stroke="#52368C"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_815_247">
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="inside-con2">
                  <h3>{t`future-academy.e2024-title-con2`}</h3>
                  <p className="p-body">
                    {t`future-academy.e2024-p-con2`}
                    <a href="mailto:hello@innovationshub.pl">{t`future-academy.e2024-p2-con2`}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edition2024;

import React, { useState, useContext } from 'react';
import '../styles/future-academy-jurors.css';
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { Jurors } from './data/jurors';
import Modal from 'react-modal';
import closeIcon from '../../../images/general/close-icon.svg';

const boxStyles = {
  overlay: {
    zIndex: 103
  },
  content: {
    margin: 'auto',
    inset: '0',
    width: '80%',
    maxHeight: '50vh',
    maxWidth: '100rem'
  }
};

const FutureAcademyJurors = () => {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const [jurors, setJurors] = useState();
  const [isModal, setIsModal] = useState(false);
  const [currentJuror, setJuror] = useState();

  React.useEffect(() => {
    const getJurors = () => {
      // if (language === 'pl') {
      setJurors(Jurors.pl);
      // } else {
      //   setJurors(Jurors.en);
      // }
    };
    getJurors();
  }, [language]);

  const renderJurors = () => {
    return jurors.map((juror) => (
      <div onClick={() => openModal(juror)} key={juror.name}>
        {juror.img}
        <h3>{juror.name}</h3>
      </div>
    ));
  };

  const openModal = (juror) => {
    setJuror(juror);
    setIsModal(true);
  };

  return (
    <>
      <Modal
        isOpen={isModal}
        style={boxStyles}
        onRequestClose={() => setIsModal(false)}
        shouldCloseOnOverlayClick={true}>
        <div className="circles">
          {/* <StaticImage
            className="circle-motive-left-mentors-details"
            src="../../../images/mentoring/circles10.png"
            alt="Circle motive left-up"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="circle-motive-right-mentors-details"
            src="../../../images/mentoring/circles11.png"
            alt="Circle motive right"
            placeholder="none"
            loading="lazy"
          /> */}
          <div className="mentors-ctn">
            <div className="modal-ctn">
              <div className="btn-close-box">
                <button onClick={() => setIsModal(false)}>
                  <img src={closeIcon} alt="close icon" />
                </button>
              </div>
              <div className="container modal-text-avatar-ctn">
                <div className="mask-border">{currentJuror?.img}</div>
                <div className="text-ctn">
                  <h2>{currentJuror?.name}</h2>
                  <p className="description">{currentJuror?.bio}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="circles">
        <StaticImage
          className="faj-circle-right"
          src="../../../images/future-academy/circle-right-faj.png"
          alt="Circle right"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="faj-circle-left"
          src="../../../images/future-academy/circle-left-faj.png"
          alt="Circle left"
          placeholder="none"
          loading="lazy"
        />
        <div className="faj-ctn">
          <div className="container">
            <h2>{t`future-academy.faj-title`}</h2>
            <p className="p-body ">{t`future-academy.faj-title2`}</p>
            <div className="faj-grid">{jurors && renderJurors()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FutureAcademyJurors;

import React from 'react';
import '../styles/what-stands-out.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const WhatStandsOut = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="circles">
        <StaticImage
          className="wso-circle-right"
          src="../../../images/future-academy/circle-right-wso.png"
          alt="Circle right"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="wso-circle-left"
          src="../../../images/future-academy/circle-left-wso.png"
          alt="Circle left"
          placeholder="none"
          loading="lazy"
        />
        <div className="wso-ctn">
          <div className="container">
            <h2>{t`future-academy.wso-title`}</h2>
            <div className="wso-grid">
              <div>
                <h4>{t`future-academy.wso-title-gr1`}</h4>
                <p className="p-body-regular">{t`future-academy.wso-p1`}</p>
              </div>
              <div>
                <h4>{t`future-academy.wso-title-gr2`}</h4>
                <p className="p-body-regular">{t`future-academy.wso-p2`}</p>
              </div>
              <div>
                <h4>{t`future-academy.wso-title-gr3`}</h4>
                <p className="p-body-regular">{t`future-academy.wso-p3`}</p>
              </div>
              <div>
                <h4>{t`future-academy.wso-title-gr4`}</h4>
                <p className="p-body-regular">{t`future-academy.wso-p4`}</p>
              </div>
              <div>
                <h4>{t`future-academy.wso-title-gr5`}</h4>
                <p className="p-body-regular">{t`future-academy.wso-p5`}</p>
              </div>
              <div>
                <h4>{t`future-academy.wso-title-gr6`}</h4>
                <p className="p-body-regular">{t`future-academy.wso-p6`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatStandsOut;

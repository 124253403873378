import React from 'react';

export const Cities = {
  pl: [
    {
      title: 'Warszawa',
      agenda: [
        {
          hour: '9:00',
          name: 'Otwarcie i integracja',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16666H6.33332C5.8913 4.16666 5.46737 4.34225 5.15481 4.65481C4.84225 4.96737 4.66666 5.3913 4.66666 5.83332V15.8333C4.66666 16.2754 4.84225 16.6993 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6993 16.3333 16.2754 16.3333 15.8333V5.83332C16.3333 5.3913 16.1577 4.96737 15.8452 4.65481C15.5326 4.34225 15.1087 4.16666 14.6667 4.16666H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3333H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '9:40',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '10:00',
          name: 'Proces Kreowania Innowacji - Discovery & Ideation Process',
          isIcon: false,
          text: 'Panel',
          duration: '60min'
        },
        {
          hour: '11:00',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '11:15',
          name: 'Warsztaty specjalistyczne; do wyboru: druk 3D, robotyka, programowanie',
          isIcon: false,
          text: 'Warsztat',
          duration: '165min'
        },
        {
          hour: '14:00',
          name: 'Przerwa obiadowa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.5 2.08333C13.0333 2.08333 15.46 2.67833 17.5833 3.73583L10.5 17.5L3.41668 3.73583C5.61849 2.64538 8.04297 2.07976 10.5 2.08333V2.08333Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.0168 6.77814C14.2584 5.88038 12.3118 5.41366 10.3376 5.41647C8.4617 5.41397 6.60948 5.83529 4.91925 6.64897"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66666 10.8248V10.8333"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3333 8.33294V8.34149"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '14:45',
          name: 'Praca w zespołach',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_398)">
                <path
                  d="M4.66666 5.83333C4.66666 6.71739 5.01785 7.56523 5.64297 8.19036C6.26809 8.81548 7.11593 9.16667 7.99999 9.16667C8.88405 9.16667 9.73189 8.81548 10.357 8.19036C10.9821 7.56523 11.3333 6.71739 11.3333 5.83333C11.3333 4.94928 10.9821 4.10143 10.357 3.47631C9.73189 2.85119 8.88405 2.5 7.99999 2.5C7.11593 2.5 6.26809 2.85119 5.64297 3.47631C5.01785 4.10143 4.66666 4.94928 4.66666 5.83333Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 17.5V15.8333C3 14.9493 3.35119 14.1014 3.97631 13.4763C4.60143 12.8512 5.44928 12.5 6.33333 12.5H9.66667C10.5507 12.5 11.3986 12.8512 12.0237 13.4763C12.6488 14.1014 13 14.9493 13 15.8333V17.5"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8333 2.60834C14.5504 2.79192 15.1859 3.20892 15.6397 3.7936C16.0935 4.37827 16.3399 5.09736 16.3399 5.8375C16.3399 6.57765 16.0935 7.29674 15.6397 7.88141C15.1859 8.46609 14.5504 8.88309 13.8333 9.06667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 17.5V15.8333C17.9958 15.0976 17.7483 14.384 17.2961 13.8037C16.8439 13.2233 16.2124 12.8089 15.5 12.625"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_398">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '120min'
        },
        {
          hour: '16:45',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '17:00',
          name: 'Podsumowanie dnia warsztatowego i wręczenie nagród',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16663H6.33332C5.8913 4.16663 5.46737 4.34222 5.15481 4.65478C4.84225 4.96734 4.66666 5.39127 4.66666 5.83329V15.8333C4.66666 16.2753 4.84225 16.6992 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6992 16.3333 16.2753 16.3333 15.8333V5.83329C16.3333 5.39127 16.1577 4.96734 15.8452 4.65478C15.5326 4.34222 15.1087 4.16663 14.6667 4.16663H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3334H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '60min'
        }
      ]
    },
    {
      title: 'Wrocław',
      agenda: [
        {
          hour: '9:00',
          name: 'Otwarcie i integracja',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16666H6.33332C5.8913 4.16666 5.46737 4.34225 5.15481 4.65481C4.84225 4.96737 4.66666 5.3913 4.66666 5.83332V15.8333C4.66666 16.2754 4.84225 16.6993 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6993 16.3333 16.2754 16.3333 15.8333V5.83332C16.3333 5.3913 16.1577 4.96737 15.8452 4.65481C15.5326 4.34225 15.1087 4.16666 14.6667 4.16666H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3333H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '9:40',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '10:00',
          name: 'Proces Kreowania Innowacji - Discovery & Ideation Process',
          isIcon: false,
          text: 'Panel',
          duration: '60min'
        },
        {
          hour: '11:00',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '11:15',
          name: 'Warsztaty specjalistyczne; do wyboru: druk 3D, robotyka, programowanie',
          isIcon: false,
          text: 'Warsztat',
          duration: '165min'
        },
        {
          hour: '14:00',
          name: 'Przerwa obiadowa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.5 2.08333C13.0333 2.08333 15.46 2.67833 17.5833 3.73583L10.5 17.5L3.41668 3.73583C5.61849 2.64538 8.04297 2.07976 10.5 2.08333V2.08333Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.0168 6.77814C14.2584 5.88038 12.3118 5.41366 10.3376 5.41647C8.4617 5.41397 6.60948 5.83529 4.91925 6.64897"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66666 10.8248V10.8333"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3333 8.33294V8.34149"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '14:45',
          name: 'Praca w zespołach',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_398)">
                <path
                  d="M4.66666 5.83333C4.66666 6.71739 5.01785 7.56523 5.64297 8.19036C6.26809 8.81548 7.11593 9.16667 7.99999 9.16667C8.88405 9.16667 9.73189 8.81548 10.357 8.19036C10.9821 7.56523 11.3333 6.71739 11.3333 5.83333C11.3333 4.94928 10.9821 4.10143 10.357 3.47631C9.73189 2.85119 8.88405 2.5 7.99999 2.5C7.11593 2.5 6.26809 2.85119 5.64297 3.47631C5.01785 4.10143 4.66666 4.94928 4.66666 5.83333Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 17.5V15.8333C3 14.9493 3.35119 14.1014 3.97631 13.4763C4.60143 12.8512 5.44928 12.5 6.33333 12.5H9.66667C10.5507 12.5 11.3986 12.8512 12.0237 13.4763C12.6488 14.1014 13 14.9493 13 15.8333V17.5"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8333 2.60834C14.5504 2.79192 15.1859 3.20892 15.6397 3.7936C16.0935 4.37827 16.3399 5.09736 16.3399 5.8375C16.3399 6.57765 16.0935 7.29674 15.6397 7.88141C15.1859 8.46609 14.5504 8.88309 13.8333 9.06667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 17.5V15.8333C17.9958 15.0976 17.7483 14.384 17.2961 13.8037C16.8439 13.2233 16.2124 12.8089 15.5 12.625"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_398">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '120min'
        },
        {
          hour: '16:45',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '17:00',
          name: 'Podsumowanie dnia warsztatowego i wręczenie nagród',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16663H6.33332C5.8913 4.16663 5.46737 4.34222 5.15481 4.65478C4.84225 4.96734 4.66666 5.39127 4.66666 5.83329V15.8333C4.66666 16.2753 4.84225 16.6992 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6992 16.3333 16.2753 16.3333 15.8333V5.83329C16.3333 5.39127 16.1577 4.96734 15.8452 4.65478C15.5326 4.34222 15.1087 4.16663 14.6667 4.16663H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3334H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '60min'
        }
      ]
    },
    {
      title: 'Kraków',
      agenda: [
        {
          hour: '9:00',
          name: 'Otwarcie i integracja',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16666H6.33332C5.8913 4.16666 5.46737 4.34225 5.15481 4.65481C4.84225 4.96737 4.66666 5.3913 4.66666 5.83332V15.8333C4.66666 16.2754 4.84225 16.6993 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6993 16.3333 16.2754 16.3333 15.8333V5.83332C16.3333 5.3913 16.1577 4.96737 15.8452 4.65481C15.5326 4.34225 15.1087 4.16666 14.6667 4.16666H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3333H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '9:40',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '10:00',
          name: 'Proces Kreowania Innowacji - Discovery & Ideation Process',
          isIcon: false,
          text: 'Panel',
          duration: '60min'
        },
        {
          hour: '11:00',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '11:15',
          name: 'Warsztaty specjalistyczne; do wyboru: druk 3D, robotyka, programowanie',
          isIcon: false,
          text: 'Warsztat',
          duration: '165min'
        },
        {
          hour: '14:00',
          name: 'Przerwa obiadowa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.5 2.08333C13.0333 2.08333 15.46 2.67833 17.5833 3.73583L10.5 17.5L3.41668 3.73583C5.61849 2.64538 8.04297 2.07976 10.5 2.08333V2.08333Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.0168 6.77814C14.2584 5.88038 12.3118 5.41366 10.3376 5.41647C8.4617 5.41397 6.60948 5.83529 4.91925 6.64897"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66666 10.8248V10.8333"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3333 8.33294V8.34149"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '14:45',
          name: 'Praca w zespołach',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_398)">
                <path
                  d="M4.66666 5.83333C4.66666 6.71739 5.01785 7.56523 5.64297 8.19036C6.26809 8.81548 7.11593 9.16667 7.99999 9.16667C8.88405 9.16667 9.73189 8.81548 10.357 8.19036C10.9821 7.56523 11.3333 6.71739 11.3333 5.83333C11.3333 4.94928 10.9821 4.10143 10.357 3.47631C9.73189 2.85119 8.88405 2.5 7.99999 2.5C7.11593 2.5 6.26809 2.85119 5.64297 3.47631C5.01785 4.10143 4.66666 4.94928 4.66666 5.83333Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 17.5V15.8333C3 14.9493 3.35119 14.1014 3.97631 13.4763C4.60143 12.8512 5.44928 12.5 6.33333 12.5H9.66667C10.5507 12.5 11.3986 12.8512 12.0237 13.4763C12.6488 14.1014 13 14.9493 13 15.8333V17.5"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8333 2.60834C14.5504 2.79192 15.1859 3.20892 15.6397 3.7936C16.0935 4.37827 16.3399 5.09736 16.3399 5.8375C16.3399 6.57765 16.0935 7.29674 15.6397 7.88141C15.1859 8.46609 14.5504 8.88309 13.8333 9.06667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 17.5V15.8333C17.9958 15.0976 17.7483 14.384 17.2961 13.8037C16.8439 13.2233 16.2124 12.8089 15.5 12.625"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_398">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '120min'
        },
        {
          hour: '16:45',
          name: 'Przerwa',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '17:00',
          name: 'Podsumowanie dnia warsztatowego i wręczenie nagród',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16663H6.33332C5.8913 4.16663 5.46737 4.34222 5.15481 4.65478C4.84225 4.96734 4.66666 5.39127 4.66666 5.83329V15.8333C4.66666 16.2753 4.84225 16.6992 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6992 16.3333 16.2753 16.3333 15.8333V5.83329C16.3333 5.39127 16.1577 4.96734 15.8452 4.65478C15.5326 4.34222 15.1087 4.16663 14.6667 4.16663H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3334H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '60min'
        }
      ]
    }
  ],
  en: [
    {
      title: 'Warsaw',
      agenda: [
        {
          hour: '9:00',
          name: 'Opening and integration',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16666H6.33332C5.8913 4.16666 5.46737 4.34225 5.15481 4.65481C4.84225 4.96737 4.66666 5.3913 4.66666 5.83332V15.8333C4.66666 16.2754 4.84225 16.6993 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6993 16.3333 16.2754 16.3333 15.8333V5.83332C16.3333 5.3913 16.1577 4.96737 15.8452 4.65481C15.5326 4.34225 15.1087 4.16666 14.6667 4.16666H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3333H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '9:40',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '10:00',
          name: 'Discovery & Ideation Process',
          isIcon: false,
          text: 'Panel',
          duration: '60min'
        },
        {
          hour: '11:00',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '11:15',
          name: 'Specialized workshops; choice of: 3D printing, robotics, programming',
          isIcon: false,
          text: 'Workshop',
          duration: '165min'
        },
        {
          hour: '14:00',
          name: 'Lunch break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.5 2.08333C13.0333 2.08333 15.46 2.67833 17.5833 3.73583L10.5 17.5L3.41668 3.73583C5.61849 2.64538 8.04297 2.07976 10.5 2.08333V2.08333Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.0168 6.77814C14.2584 5.88038 12.3118 5.41366 10.3376 5.41647C8.4617 5.41397 6.60948 5.83529 4.91925 6.64897"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66666 10.8248V10.8333"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3333 8.33294V8.34149"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '14:45',
          name: 'Teamwork',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_398)">
                <path
                  d="M4.66666 5.83333C4.66666 6.71739 5.01785 7.56523 5.64297 8.19036C6.26809 8.81548 7.11593 9.16667 7.99999 9.16667C8.88405 9.16667 9.73189 8.81548 10.357 8.19036C10.9821 7.56523 11.3333 6.71739 11.3333 5.83333C11.3333 4.94928 10.9821 4.10143 10.357 3.47631C9.73189 2.85119 8.88405 2.5 7.99999 2.5C7.11593 2.5 6.26809 2.85119 5.64297 3.47631C5.01785 4.10143 4.66666 4.94928 4.66666 5.83333Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 17.5V15.8333C3 14.9493 3.35119 14.1014 3.97631 13.4763C4.60143 12.8512 5.44928 12.5 6.33333 12.5H9.66667C10.5507 12.5 11.3986 12.8512 12.0237 13.4763C12.6488 14.1014 13 14.9493 13 15.8333V17.5"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8333 2.60834C14.5504 2.79192 15.1859 3.20892 15.6397 3.7936C16.0935 4.37827 16.3399 5.09736 16.3399 5.8375C16.3399 6.57765 16.0935 7.29674 15.6397 7.88141C15.1859 8.46609 14.5504 8.88309 13.8333 9.06667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 17.5V15.8333C17.9958 15.0976 17.7483 14.384 17.2961 13.8037C16.8439 13.2233 16.2124 12.8089 15.5 12.625"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_398">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '120min'
        },
        {
          hour: '16:45',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '17:00',
          name: 'Summary of the workshop day and award ceremony',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16663H6.33332C5.8913 4.16663 5.46737 4.34222 5.15481 4.65478C4.84225 4.96734 4.66666 5.39127 4.66666 5.83329V15.8333C4.66666 16.2753 4.84225 16.6992 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6992 16.3333 16.2753 16.3333 15.8333V5.83329C16.3333 5.39127 16.1577 4.96734 15.8452 4.65478C15.5326 4.34222 15.1087 4.16663 14.6667 4.16663H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3334H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '60min'
        }
      ]
    },
    {
      title: 'Wroclaw',
      agenda: [
        {
          hour: '9:00',
          name: 'Opening and integration',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16666H6.33332C5.8913 4.16666 5.46737 4.34225 5.15481 4.65481C4.84225 4.96737 4.66666 5.3913 4.66666 5.83332V15.8333C4.66666 16.2754 4.84225 16.6993 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6993 16.3333 16.2754 16.3333 15.8333V5.83332C16.3333 5.3913 16.1577 4.96737 15.8452 4.65481C15.5326 4.34225 15.1087 4.16666 14.6667 4.16666H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3333H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '9:40',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '10:00',
          name: 'Discovery & Ideation Process',
          isIcon: false,
          text: 'Panel',
          duration: '60min'
        },
        {
          hour: '11:00',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '11:15',
          name: 'Specialized workshops; choice of: 3D printing, robotics, programming',
          isIcon: false,
          text: 'Workshop',
          duration: '165min'
        },
        {
          hour: '14:00',
          name: 'Lunch break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.5 2.08333C13.0333 2.08333 15.46 2.67833 17.5833 3.73583L10.5 17.5L3.41668 3.73583C5.61849 2.64538 8.04297 2.07976 10.5 2.08333V2.08333Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.0168 6.77814C14.2584 5.88038 12.3118 5.41366 10.3376 5.41647C8.4617 5.41397 6.60948 5.83529 4.91925 6.64897"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66666 10.8248V10.8333"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3333 8.33294V8.34149"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '14:45',
          name: 'Teamwork',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_398)">
                <path
                  d="M4.66666 5.83333C4.66666 6.71739 5.01785 7.56523 5.64297 8.19036C6.26809 8.81548 7.11593 9.16667 7.99999 9.16667C8.88405 9.16667 9.73189 8.81548 10.357 8.19036C10.9821 7.56523 11.3333 6.71739 11.3333 5.83333C11.3333 4.94928 10.9821 4.10143 10.357 3.47631C9.73189 2.85119 8.88405 2.5 7.99999 2.5C7.11593 2.5 6.26809 2.85119 5.64297 3.47631C5.01785 4.10143 4.66666 4.94928 4.66666 5.83333Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 17.5V15.8333C3 14.9493 3.35119 14.1014 3.97631 13.4763C4.60143 12.8512 5.44928 12.5 6.33333 12.5H9.66667C10.5507 12.5 11.3986 12.8512 12.0237 13.4763C12.6488 14.1014 13 14.9493 13 15.8333V17.5"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8333 2.60834C14.5504 2.79192 15.1859 3.20892 15.6397 3.7936C16.0935 4.37827 16.3399 5.09736 16.3399 5.8375C16.3399 6.57765 16.0935 7.29674 15.6397 7.88141C15.1859 8.46609 14.5504 8.88309 13.8333 9.06667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 17.5V15.8333C17.9958 15.0976 17.7483 14.384 17.2961 13.8037C16.8439 13.2233 16.2124 12.8089 15.5 12.625"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_398">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '120min'
        },
        {
          hour: '16:45',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '17:00',
          name: 'Summary of the workshop day and award ceremony',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16663H6.33332C5.8913 4.16663 5.46737 4.34222 5.15481 4.65478C4.84225 4.96734 4.66666 5.39127 4.66666 5.83329V15.8333C4.66666 16.2753 4.84225 16.6992 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6992 16.3333 16.2753 16.3333 15.8333V5.83329C16.3333 5.39127 16.1577 4.96734 15.8452 4.65478C15.5326 4.34222 15.1087 4.16663 14.6667 4.16663H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3334H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '60min'
        }
      ]
    },
    {
      title: 'Cracow',
      agenda: [
        {
          hour: '9:00',
          name: 'Opening and integration',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16666H6.33332C5.8913 4.16666 5.46737 4.34225 5.15481 4.65481C4.84225 4.96737 4.66666 5.3913 4.66666 5.83332V15.8333C4.66666 16.2754 4.84225 16.6993 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6993 16.3333 16.2754 16.3333 15.8333V5.83332C16.3333 5.3913 16.1577 4.96737 15.8452 4.65481C15.5326 4.34225 15.1087 4.16666 14.6667 4.16666H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3333H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '9:40',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '10:00',
          name: 'Discovery & Ideation Process',
          isIcon: false,
          text: 'Panel',
          duration: '60min'
        },
        {
          hour: '11:00',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '11:15',
          name: 'Specialized workshops; choice of: 3D printing, robotics, programming',
          isIcon: false,
          text: 'Workshop',
          duration: '165min'
        },
        {
          hour: '14:00',
          name: 'Lunch break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M10.5 2.08333C13.0333 2.08333 15.46 2.67833 17.5833 3.73583L10.5 17.5L3.41668 3.73583C5.61849 2.64538 8.04297 2.07976 10.5 2.08333V2.08333Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.0168 6.77814C14.2584 5.88038 12.3118 5.41366 10.3376 5.41647C8.4617 5.41397 6.60948 5.83529 4.91925 6.64897"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66666 10.8248V10.8333"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.3333 8.33294V8.34149"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '45min'
        },
        {
          hour: '14:45',
          name: 'Teamwork',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_398)">
                <path
                  d="M4.66666 5.83333C4.66666 6.71739 5.01785 7.56523 5.64297 8.19036C6.26809 8.81548 7.11593 9.16667 7.99999 9.16667C8.88405 9.16667 9.73189 8.81548 10.357 8.19036C10.9821 7.56523 11.3333 6.71739 11.3333 5.83333C11.3333 4.94928 10.9821 4.10143 10.357 3.47631C9.73189 2.85119 8.88405 2.5 7.99999 2.5C7.11593 2.5 6.26809 2.85119 5.64297 3.47631C5.01785 4.10143 4.66666 4.94928 4.66666 5.83333Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 17.5V15.8333C3 14.9493 3.35119 14.1014 3.97631 13.4763C4.60143 12.8512 5.44928 12.5 6.33333 12.5H9.66667C10.5507 12.5 11.3986 12.8512 12.0237 13.4763C12.6488 14.1014 13 14.9493 13 15.8333V17.5"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8333 2.60834C14.5504 2.79192 15.1859 3.20892 15.6397 3.7936C16.0935 4.37827 16.3399 5.09736 16.3399 5.8375C16.3399 6.57765 16.0935 7.29674 15.6397 7.88141C15.1859 8.46609 14.5504 8.88309 13.8333 9.06667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 17.5V15.8333C17.9958 15.0976 17.7483 14.384 17.2961 13.8037C16.8439 13.2233 16.2124 12.8089 15.5 12.625"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_398">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '120min'
        },
        {
          hour: '16:45',
          name: 'Break',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <g clip-path="url(#clip0_826_352)">
                <path
                  d="M3 11.6667C3.69167 12.2017 4.73083 12.5142 5.91667 12.5C7.1025 12.5142 8.14167 12.2017 8.83333 11.6667C9.525 11.1317 10.5642 10.8192 11.75 10.8333C12.9358 10.8192 13.975 11.1317 14.6667 11.6667"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.16666 2.5C6.90291 2.68931 6.68922 2.93998 6.54403 3.23035C6.39884 3.52073 6.32653 3.84209 6.33332 4.16667C6.32653 4.49125 6.39884 4.8126 6.54403 5.10298C6.68922 5.39336 6.90291 5.64402 7.16666 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 2.5C10.2363 2.68931 10.0226 2.93998 9.87738 3.23035C9.73219 3.52073 9.65987 3.84209 9.66667 4.16667C9.65987 4.49125 9.73219 4.8126 9.87738 5.10298C10.0226 5.39336 10.2363 5.64402 10.5 5.83333"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 8.33331H14.6667V12.5C14.6667 13.8261 14.1399 15.0978 13.2022 16.0355C12.2645 16.9732 10.9928 17.5 9.66667 17.5H8C6.67392 17.5 5.40215 16.9732 4.46447 16.0355C3.52678 15.0978 3 13.8261 3 12.5V8.33331Z"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.455 13.9384C14.8272 14.1092 15.2356 14.186 15.6444 14.162C16.0532 14.1381 16.4498 14.014 16.7995 13.8008C17.1491 13.5876 17.441 13.2918 17.6496 12.9394C17.8581 12.587 17.9769 12.1887 17.9954 11.7796C18.014 11.3705 17.9318 10.9631 17.756 10.5933C17.5802 10.2234 17.3163 9.90239 16.9874 9.65842C16.6585 9.41445 16.2747 9.25501 15.8698 9.19412C15.4648 9.13322 15.0511 9.17274 14.665 9.30919"
                  stroke="#52368C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_826_352">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          ),
          duration: '15min'
        },
        {
          hour: '17:00',
          name: 'Summary of the workshop day and award ceremony',
          isIcon: true,
          icon: (
            <svg
              className="con2-right-image"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none">
              <path
                d="M7.99999 4.16663H6.33332C5.8913 4.16663 5.46737 4.34222 5.15481 4.65478C4.84225 4.96734 4.66666 5.39127 4.66666 5.83329V15.8333C4.66666 16.2753 4.84225 16.6992 5.15481 17.0118C5.46737 17.3244 5.8913 17.5 6.33332 17.5H14.6667C15.1087 17.5 15.5326 17.3244 15.8452 17.0118C16.1577 16.6992 16.3333 16.2753 16.3333 15.8333V5.83329C16.3333 5.39127 16.1577 4.96734 15.8452 4.65478C15.5326 4.34222 15.1087 4.16663 14.6667 4.16663H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4.16667C8 3.72464 8.1756 3.30072 8.48816 2.98816C8.80072 2.67559 9.22464 2.5 9.66667 2.5H11.3333C11.7754 2.5 12.1993 2.67559 12.5118 2.98816C12.8244 3.30072 13 3.72464 13 4.16667C13 4.60869 12.8244 5.03262 12.5118 5.34518C12.1993 5.65774 11.7754 5.83333 11.3333 5.83333H9.66667C9.22464 5.83333 8.80072 5.65774 8.48816 5.34518C8.1756 5.03262 8 4.60869 8 4.16667Z"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 10H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 13.3334H13"
                stroke="#52368C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ),
          duration: '60min'
        }
      ]
    }
  ]
};

import React from 'react';
import '../styles/future-academy-header.css';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const FutureAcademyHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fa-header-ctn">
        <div className="container">
          <div className="fa-text-ctn">
            <FutureAcademyLogo/>
            <h2>{t`future-academy.fah-title`}</h2>
            <a
              href="#whatStandsOut"
              className="fa-btn btn-fa-header">{t`future-academy.fah-button`}</a>
          </div>
        </div>
      </div>
    </>
  );
};

const FutureAcademyLogo = () => {
  return (
    <svg
      width="182"
      height="41"
      viewBox="0 0 182 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_756_7519)">
        <g filter="url(#filter0_d_756_7519)">
          <path
            d="M66.5162 18.4353H57.0361L55.0749 22.8942H52.4514L60.5291 5.05884H63.0527L71.1563 22.8942H68.481L66.5199 18.4353H66.5162ZM65.6244 16.3965L61.7761 7.65643L57.9278 16.3965H65.6244Z"
            fill="white"
          />
          <path
            d="M78.6975 16.6L75.8964 19.1976V22.8905H73.4505V3.9895H75.8964V16.119L83.2599 9.39191H86.2165L80.5328 14.9719L86.7752 22.8979H83.7706L78.7012 16.6037L78.6975 16.6Z"
            fill="white"
          />
          <path
            d="M98.098 10.639C99.0934 11.5566 99.5893 12.922 99.5893 14.7426V22.898H97.2692V21.1144C96.8622 21.7435 96.2812 22.2245 95.5227 22.5538C94.7678 22.8869 93.8686 23.0497 92.8363 23.0497C91.3228 23.0497 90.1128 22.6834 89.2063 21.9544C88.296 21.2254 87.8446 20.2634 87.8446 19.0756C87.8446 17.8878 88.2775 16.9331 89.1434 16.2079C90.0092 15.4863 91.3857 15.1237 93.2729 15.1237H97.1471V14.639C97.1471 13.5844 96.84 12.7777 96.2294 12.219C95.6189 11.6565 94.716 11.379 93.5282 11.379C92.7289 11.379 91.9482 11.5122 91.1859 11.7749C90.42 12.0377 89.7761 12.3892 89.2507 12.8332L88.2331 10.9979C88.9288 10.4391 89.7613 10.0099 90.7308 9.71018C91.7003 9.41416 92.7252 9.26245 93.8131 9.26245C95.6818 9.26245 97.1138 9.72129 98.1054 10.639H98.098ZM95.6633 20.5372C96.3441 20.1228 96.8362 19.5307 97.1397 18.7648V16.8776H93.3691C91.2969 16.8776 90.2609 17.5733 90.2609 18.9683C90.2609 19.6491 90.5236 20.182 91.049 20.5742C91.5745 20.9664 92.3071 21.1588 93.2396 21.1588C94.172 21.1588 94.9824 20.9516 95.6596 20.5335L95.6633 20.5372Z"
            fill="white"
          />
          <path
            d="M116.94 3.9895V22.8942H114.598V20.7555C114.054 21.5029 113.365 22.0728 112.533 22.4613C111.7 22.8535 110.783 23.0459 109.78 23.0459C108.47 23.0459 107.301 22.7573 106.265 22.1801C105.228 21.6028 104.418 20.7925 103.83 19.7453C103.245 18.7018 102.949 17.4992 102.949 16.1412C102.949 14.7832 103.241 13.5843 103.83 12.5482C104.414 11.5122 105.228 10.7055 106.265 10.1283C107.301 9.55102 108.474 9.2624 109.78 9.2624C110.749 9.2624 111.641 9.44371 112.455 9.81004C113.269 10.1764 113.95 10.7129 114.494 11.4271V3.9895H116.94ZM112.303 20.3077C112.999 19.9081 113.543 19.3494 113.935 18.6278C114.327 17.9062 114.52 17.0774 114.52 16.1449C114.52 15.2124 114.324 14.3836 113.935 13.662C113.543 12.9405 112.999 12.3854 112.303 11.9932C111.608 11.601 110.834 11.4086 109.983 11.4086C109.132 11.4086 108.34 11.6047 107.652 11.9932C106.964 12.3854 106.42 12.9405 106.02 13.662C105.621 14.3836 105.421 15.2124 105.421 16.1449C105.421 17.0774 105.621 17.9062 106.02 18.6278C106.42 19.3494 106.964 19.9118 107.652 20.3077C108.34 20.7074 109.117 20.9072 109.983 20.9072C110.849 20.9072 111.604 20.7074 112.303 20.3077Z"
            fill="white"
          />
          <path
            d="M133.732 16.959H122.775C122.927 18.1468 123.449 19.1052 124.34 19.8267C125.232 20.5483 126.342 20.9109 127.667 20.9109C129.28 20.9109 130.579 20.3669 131.567 19.2791L132.918 20.8591C132.307 21.5732 131.545 22.1172 130.638 22.4909C129.728 22.8646 128.714 23.0496 127.593 23.0496C126.165 23.0496 124.899 22.7573 123.796 22.169C122.694 21.5843 121.839 20.7629 121.236 19.712C120.633 18.6574 120.333 17.4696 120.333 16.1449C120.333 14.8202 120.625 13.6583 121.214 12.6038C121.798 11.5492 122.605 10.7314 123.634 10.1468C124.662 9.56214 125.821 9.26611 127.112 9.26611C128.403 9.26611 129.554 9.55843 130.564 10.1468C131.574 10.7314 132.366 11.5529 132.932 12.6038C133.502 13.6583 133.787 14.8646 133.787 16.2226C133.787 16.4113 133.769 16.6556 133.735 16.9627L133.732 16.959ZM124.163 12.3965C123.375 13.1107 122.908 14.0432 122.775 15.1977H131.438C131.301 14.0617 130.838 13.1292 130.05 12.4076C129.262 11.6861 128.278 11.3235 127.108 11.3235C125.939 11.3235 124.955 11.6787 124.166 12.3928L124.163 12.3965Z"
            fill="white"
          />
          <path
            d="M158.102 10.7389C159.097 11.7231 159.593 13.1847 159.593 15.12V22.8906H157.147V15.4012C157.147 14.0913 156.844 13.107 156.244 12.4447C155.641 11.7823 154.779 11.4493 153.658 11.4493C152.418 11.4493 151.434 11.8341 150.701 12.6075C149.972 13.3809 149.606 14.4872 149.606 15.9341V22.8906H147.16V15.4012C147.16 14.0913 146.857 13.107 146.257 12.4447C145.654 11.7823 144.792 11.4493 143.671 11.4493C142.431 11.4493 141.447 11.8341 140.714 12.6075C139.985 13.3809 139.619 14.4872 139.619 15.9341V22.8906H137.173V9.38826H139.515V11.4012C140.007 10.7056 140.655 10.1727 141.45 9.81009C142.25 9.44376 143.156 9.26245 144.178 9.26245C145.199 9.26245 146.165 9.47707 146.979 9.8989C147.793 10.3244 148.422 10.9424 148.866 11.7601C149.376 10.9794 150.083 10.3688 150.994 9.9248C151.904 9.48447 152.925 9.26245 154.065 9.26245C155.763 9.26245 157.11 9.75459 158.102 10.7389Z"
            fill="white"
          />
          <path
            d="M164.141 6.3318C163.826 6.02468 163.671 5.65465 163.671 5.21062C163.671 4.76659 163.826 4.39286 164.141 4.07833C164.455 3.76381 164.851 3.6084 165.325 3.6084C165.798 3.6084 166.194 3.75641 166.509 4.05613C166.823 4.35215 166.979 4.72218 166.979 5.16622C166.979 5.61025 166.82 6.00988 166.509 6.3244C166.194 6.63893 165.798 6.79434 165.325 6.79434C164.851 6.79434 164.455 6.64263 164.141 6.3355V6.3318ZM164.104 9.38823H166.549V22.8942H164.104V9.38823Z"
            fill="white"
          />
          <path
            d="M180.422 10.639C181.417 11.5566 181.913 12.922 181.913 14.7426V22.898H179.593V21.1144C179.186 21.7435 178.605 22.2245 177.846 22.5538C177.092 22.8869 176.192 23.0497 175.16 23.0497C173.647 23.0497 172.437 22.6834 171.53 21.9544C170.62 21.2254 170.168 20.2634 170.168 19.0756C170.168 17.8878 170.601 16.9331 171.467 16.2079C172.333 15.4863 173.709 15.1237 175.597 15.1237H179.471V14.639C179.471 13.5844 179.164 12.7777 178.553 12.219C177.943 11.6565 177.04 11.379 175.852 11.379C175.053 11.379 174.272 11.5122 173.51 11.7749C172.744 12.0377 172.1 12.3892 171.574 12.8332L170.557 10.9979C171.253 10.4391 172.085 10.0099 173.055 9.71018C174.024 9.41416 175.049 9.26245 176.137 9.26245C178.006 9.26245 179.438 9.72129 180.429 10.639H180.422ZM177.987 20.5372C178.668 20.1228 179.16 19.5307 179.463 18.7648V16.8776H175.693C173.621 16.8776 172.585 17.5733 172.585 18.9683C172.585 19.6491 172.847 20.182 173.373 20.5742C173.898 20.9664 174.631 21.1588 175.563 21.1588C176.496 21.1588 177.306 20.9516 177.983 20.5335L177.987 20.5372Z"
            fill="white"
          />
        </g>
        <path
          d="M60.2701 27.1384C61.1027 27.8341 61.5171 28.7887 61.5171 30.0024C61.5171 31.2161 61.1027 32.1745 60.2701 32.8664C59.4375 33.5584 58.2979 33.9062 56.8473 33.9062H54.0795V37.2994H52.481V26.0986H56.851C58.3016 26.0986 59.4449 26.4465 60.2738 27.1384H60.2701ZM59.1193 31.8674C59.6522 31.4344 59.9186 30.8128 59.9186 30.0024C59.9186 29.1921 59.6522 28.5704 59.1193 28.1375C58.5865 27.7046 57.8131 27.4899 56.7992 27.4899H54.0795V32.5149H56.7992C57.8131 32.5149 58.5865 32.3003 59.1193 31.8674Z"
          fill="#4EBFEE"
        />
        <path
          d="M66.161 29.1216C66.6827 28.8663 67.3192 28.7368 68.0666 28.7368V30.2243C67.9815 30.2132 67.8631 30.2095 67.7151 30.2095C66.8825 30.2095 66.2276 30.4574 65.7539 30.9533C65.2803 31.4491 65.0435 32.1559 65.0435 33.0735V37.2993H63.5079V28.8182H64.9806V30.2428C65.247 29.7507 65.6429 29.377 66.1647 29.1216H66.161Z"
          fill="#4EBFEE"
        />
        <path
          d="M76.3552 36.0339V37.2994H69.2507V36.3077L74.2609 30.0839H69.3469V28.8184H76.2294V29.8248L71.2192 36.0339H76.3552Z"
          fill="#4EBFEE"
        />
        <path
          d="M85.7465 28.8184L81.6355 38.1468C81.2729 39.0126 80.8474 39.6195 80.3626 39.971C79.8779 40.3225 79.2932 40.5001 78.6087 40.5001C78.1943 40.5001 77.7909 40.4335 77.3987 40.3003C77.0102 40.1671 76.6882 39.971 76.4292 39.7157L77.0842 38.5649C77.5208 38.9793 78.0277 39.1902 78.605 39.1902C78.9787 39.1902 79.2895 39.0903 79.5412 38.8942C79.7928 38.6981 80.0185 38.3577 80.222 37.8766L80.4958 37.2846L76.7512 28.8184H78.3497L81.3099 35.6047L84.2368 28.8184H85.7428H85.7465Z"
          fill="#4EBFEE"
        />
        <path
          d="M87.5078 37.1142C86.8677 36.9292 86.3645 36.6887 86.0018 36.4038L86.642 35.1864C87.0157 35.4528 87.4671 35.6674 88.0037 35.8265C88.5365 35.9856 89.0768 36.067 89.6207 36.067C90.9639 36.067 91.6374 35.6822 91.6374 34.9162C91.6374 34.6609 91.5448 34.4574 91.3672 34.3094C91.1859 34.1614 90.9602 34.0504 90.6864 33.9801C90.4163 33.9098 90.0277 33.8321 89.5245 33.747C88.8399 33.6396 88.2849 33.5175 87.852 33.3806C87.419 33.24 87.049 33.0069 86.7382 32.6776C86.4274 32.3482 86.2757 31.882 86.2757 31.2863C86.2757 30.5166 86.5976 29.9024 87.2377 29.4361C87.8779 28.9699 88.7363 28.7405 89.8131 28.7405C90.3793 28.7405 90.9454 28.8108 91.5078 28.9477C92.074 29.0883 92.5365 29.2733 92.8992 29.5064L92.2442 30.7238C91.5522 30.2761 90.7345 30.0504 89.7946 30.0504C89.1434 30.0504 88.6475 30.1577 88.3071 30.3723C87.9667 30.5869 87.7965 30.8681 87.7965 31.2197C87.7965 31.4972 87.8927 31.7155 88.0851 31.8746C88.2775 32.0337 88.5143 32.1521 88.7955 32.2261C89.0768 32.3001 89.4764 32.3853 89.987 32.4815C90.6716 32.5999 91.2229 32.7257 91.6448 32.8589C92.0666 32.9921 92.4255 33.2178 92.7252 33.5397C93.025 33.858 93.173 34.3094 93.173 34.8829C93.173 35.6526 92.8436 36.2631 92.1887 36.7146C91.5337 37.1697 90.6383 37.3954 89.5097 37.3954C88.8177 37.3954 88.148 37.3029 87.5078 37.1142Z"
          fill="#4EBFEE"
        />
        <path
          d="M101.384 36.0339V37.2994H94.2794V36.3077L99.2895 30.0839H94.3756V28.8184H101.258V29.8248L96.2479 36.0339H101.384Z"
          fill="#4EBFEE"
        />
        <path
          d="M101.861 32.722L106.102 29.488L106.79 30.4167L102.549 33.6323L101.861 32.722ZM103.556 25.425H105.092V37.2992H103.556V25.425Z"
          fill="#4EBFEE"
        />
        <path
          d="M109.513 36.833C108.84 36.4593 108.315 35.9449 107.937 35.29C107.56 34.635 107.367 33.8913 107.367 33.0587C107.367 32.2261 107.556 31.4824 107.937 30.8274C108.318 30.1725 108.84 29.6581 109.513 29.2918C110.187 28.9218 110.942 28.7405 111.785 28.7405C112.629 28.7405 113.384 28.9255 114.05 29.2918C114.716 29.6581 115.238 30.1725 115.619 30.8274C116 31.4824 116.189 32.2261 116.189 33.0587C116.189 33.8913 116 34.635 115.619 35.29C115.238 35.9449 114.716 36.463 114.05 36.833C113.384 37.2067 112.629 37.3917 111.785 37.3917C110.942 37.3917 110.187 37.2067 109.513 36.833ZM113.251 35.6711C113.684 35.4195 114.02 35.068 114.268 34.6165C114.512 34.1614 114.635 33.6433 114.635 33.055C114.635 32.4667 114.512 31.9486 114.268 31.4935C114.024 31.0421 113.684 30.6905 113.251 30.4463C112.818 30.2021 112.329 30.08 111.785 30.08C111.241 30.08 110.753 30.2021 110.32 30.4463C109.887 30.6905 109.547 31.0421 109.295 31.4935C109.043 31.9486 108.918 32.4667 108.918 33.055C108.918 33.6433 109.043 34.1614 109.295 34.6165C109.547 35.0717 109.887 35.4232 110.32 35.6711C110.753 35.9227 111.241 36.0485 111.785 36.0485C112.329 36.0485 112.818 35.9227 113.251 35.6711Z"
          fill="#4EBFEE"
        />
        <path
          d="M118.746 37.1144C118.105 36.9294 117.602 36.6889 117.24 36.4039L117.88 35.1866C118.253 35.453 118.705 35.6676 119.241 35.8267C119.774 35.9858 120.315 36.0672 120.858 36.0672C122.202 36.0672 122.875 35.6824 122.875 34.9164C122.875 34.6611 122.783 34.4576 122.605 34.3096C122.424 34.1616 122.198 34.0506 121.924 33.9803C121.654 33.91 121.265 33.8323 120.762 33.7471C120.078 33.6398 119.523 33.5177 119.09 33.3808C118.657 33.2402 118.287 33.0071 117.976 32.6778C117.665 32.3484 117.513 31.8822 117.513 31.2865C117.513 30.5168 117.835 29.9026 118.475 29.4363C119.116 28.9701 119.974 28.7407 121.051 28.7407C121.617 28.7407 122.183 28.811 122.746 28.9479C123.312 29.0885 123.774 29.2735 124.137 29.5066L123.482 30.724C122.79 30.2763 121.972 30.0506 121.032 30.0506C120.381 30.0506 119.885 30.1579 119.545 30.3725C119.204 30.5871 119.034 30.8683 119.034 31.2199C119.034 31.4974 119.13 31.7157 119.323 31.8748C119.515 32.0339 119.752 32.1523 120.033 32.2263C120.315 32.3003 120.714 32.3854 121.225 32.4817C121.909 32.6001 122.461 32.7259 122.883 32.8591C123.304 32.9923 123.663 33.218 123.963 33.5399C124.263 33.8582 124.411 34.3096 124.411 34.8831C124.411 35.6528 124.081 36.2633 123.426 36.7148C122.772 37.1699 121.876 37.3956 120.747 37.3956C120.056 37.3956 119.386 37.3031 118.746 37.1144ZM121.802 25.5029H123.756L121.066 27.5825H119.626L121.802 25.5029Z"
          fill="#4EBFEE"
        />
        <path
          d="M127.715 36.833C127.038 36.4593 126.505 35.9449 126.124 35.29C125.739 34.635 125.547 33.8913 125.547 33.0587C125.547 32.2261 125.739 31.4824 126.124 30.8274C126.509 30.1725 127.038 29.6581 127.715 29.2918C128.392 28.9218 129.162 28.7405 130.028 28.7405C130.797 28.7405 131.482 28.8959 132.085 29.203C132.688 29.5138 133.154 29.9616 133.484 30.5462L132.315 31.2974C132.048 30.8903 131.719 30.5869 131.323 30.3834C130.927 30.1799 130.49 30.08 130.009 30.08C129.454 30.08 128.955 30.2021 128.514 30.4463C128.07 30.6905 127.726 31.0421 127.475 31.4935C127.223 31.9486 127.097 32.4667 127.097 33.055C127.097 33.6433 127.223 34.1799 127.475 34.6313C127.726 35.0865 128.07 35.4343 128.514 35.6785C128.958 35.9227 129.454 36.0485 130.009 36.0485C130.49 36.0485 130.927 35.9486 131.323 35.7451C131.719 35.5416 132.048 35.2382 132.315 34.8348L133.484 35.5712C133.154 36.1595 132.685 36.6073 132.085 36.9218C131.482 37.2363 130.797 37.3954 130.028 37.3954C129.162 37.3954 128.392 37.2104 127.715 36.8367V36.833Z"
          fill="#4EBFEE"
        />
        <path
          d="M135.493 26.8978C135.297 26.7053 135.197 26.4722 135.197 26.1947C135.197 25.9172 135.297 25.6804 135.493 25.4843C135.689 25.2881 135.937 25.1882 136.237 25.1882C136.537 25.1882 136.784 25.2807 136.981 25.4695C137.177 25.6545 137.277 25.8876 137.277 26.1651C137.277 26.4426 137.177 26.6942 136.981 26.8941C136.784 27.0902 136.537 27.1901 136.237 27.1901C135.937 27.1901 135.689 27.0939 135.493 26.9015V26.8978ZM135.471 28.8182H137.006V37.2992H135.471V28.8182Z"
          fill="#4EBFEE"
        />
        <path
          d="M44.4477 38.6795C43.0083 40.1189 40.6772 40.1189 39.234 38.6795C38.8603 38.3058 38.5828 37.8691 38.4015 37.4029L25.3802 5.80991L25.3728 5.79141C25.1434 5.31408 25.0176 4.77754 25.0176 4.21139C25.0176 2.17624 26.6679 0.522217 28.7031 0.522217C30.2424 0.522217 31.5634 1.46579 32.111 2.80899H32.1147L45.2544 34.6869C45.2618 34.7054 45.2692 34.7239 45.2766 34.7424C45.7872 36.0597 45.5097 37.6138 44.444 38.6795H44.4477Z"
          fill="url(#paint0_linear_756_7519)"
        />
        <path
          d="M32.0851 5.64714H32.0925L18.8603 37.4399L18.8196 37.5398C18.246 38.8312 16.9546 39.7341 15.4486 39.7341C13.4135 39.7341 11.7595 38.0838 11.7595 36.0486C11.7595 35.5158 11.8705 35.0125 12.074 34.5574L25.2211 2.96814L25.2285 2.94594L25.2988 2.77202C25.4764 2.34279 25.7391 1.93206 26.0906 1.58053C27.53 0.141125 29.8649 0.141125 31.3043 1.58053C32.407 2.68322 32.6623 4.30394 32.0814 5.64714H32.0851Z"
          fill="url(#paint1_linear_756_7519)"
        />
        <path
          d="M0.281211 28.6702C-0.499548 26.7905 0.39592 24.6332 2.27566 23.8525C2.7641 23.6489 3.27474 23.5601 3.77427 23.5712L37.9463 23.6341H37.9648C38.494 23.6045 39.0379 23.6933 39.5597 23.9117C41.4394 24.6924 42.3349 26.846 41.5541 28.7294C40.9658 30.154 39.5856 31.0088 38.1387 31.0051V31.0125L3.65956 30.9496C3.64106 30.9496 3.61886 30.9496 3.59666 30.9496C2.18315 30.9163 0.854754 30.0652 0.277511 28.6739L0.281211 28.6702Z"
          fill="url(#paint2_linear_756_7519)"
        />
        <path
          d="M36.2146 12.748L45.2581 34.687C45.2581 34.687 45.2729 34.724 45.2803 34.7425C45.7909 36.0598 45.5134 37.6139 44.4477 38.6796C43.0083 40.119 40.6772 40.119 39.2341 38.6796C38.8603 38.3059 38.5828 37.8692 38.4015 37.403L29.2951 15.3049"
          fill="url(#paint3_linear_756_7519)"
        />
        <path
          d="M41.839 39.7601C43.8745 39.7601 45.5245 38.1101 45.5245 36.0746C45.5245 34.0392 43.8745 32.3892 41.839 32.3892C39.8036 32.3892 38.1536 34.0392 38.1536 36.0746C38.1536 38.1101 39.8036 39.7601 41.839 39.7601Z"
          fill="#4EBFEE"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_756_7519"
          x="40.3714"
          y="-8.4716"
          width="153.622"
          height="43.6014"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.04" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.00392157 0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0.26 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_756_7519"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_756_7519"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_756_7519"
          x1="42.9047"
          y1="38.7646"
          x2="28"
          y2="2.39456"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#2B95B7" />
          <stop offset="1" stop-color="#52368C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_756_7519"
          x1="30.2312"
          y1="1.73964"
          x2="13.7243"
          y2="38.9422"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#4EBFEE" />
          <stop offset="1" stop-color="#52368C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_756_7519"
          x1="0.884614"
          y1="27.4087"
          x2="40.1899"
          y2="27.5568"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#2B95B7" />
          <stop offset="1" stop-color="#52368C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_756_7519"
          x1="43.0157"
          y1="39.0089"
          x2="27.8224"
          y2="1.92843"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#2B95B7" />
          <stop offset="1" stop-color="#52368C" />
        </linearGradient>
        <clipPath id="clip0_756_7519">
          <rect
            width="181.913"
            height="40"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export {FutureAcademyLogo}


export default FutureAcademyHeader;

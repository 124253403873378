import React from 'react';
import '../styles/what-is-future-academy.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const WhatIsFutureAcademy = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="circles">
        <StaticImage
          className="wifa-circle-right"
          src="../../../images/future-academy/circle-right-wifa.png"
          alt="Circle right"
          placeholder="none"
          loading="lazy"
        />
        <div className="container wifa-ctn">
          <div className="wifa-text-ctn">
            <h2>{t`future-academy.wifa-title`}</h2>
            <p className="p-body-regular">{t`future-academy.wifa-p`}</p>
          </div>
          <StaticImage
            src="../../../images/future-academy/image-circle-wifa.png"
            alt="Future academy circle image"
            placeholder="none"
            loading="eager"
            quality={100}
            className="wifa-img"
          />
        </div>
      </div>
    </>
  );
};

export default WhatIsFutureAcademy;

import React from 'react';
import '../styles/future-academy-partners.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const FutureAcademyPartners = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fap-ctn">
        <div className="container">
          <h2>{t`future-academy.fap-title`}</h2>
          <h4>{t`future-academy.fap-title2`}</h4>
          <div className="fap-grid-up">
            <StaticImage
              class="fap-up-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Uniwersytet Warszawski"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-up-image"
              src="../../../images/future-academy/aip2-fa.png"
              alt="Politechnika Wrocławska"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-up-image"
              src="../../../images/future-academy/agh-fa.png"
              alt="AGH"
              placeholder="none"
              loading="lazy"
            />
          </div>
          {/* <h4 className="h4-title3">{t`future-academy.fap-title3`}</h4>
          <div className="fap-grid-ps">
            <StaticImage
              class="fap-ps-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
          </div> */}
          <div className="fap-grid-pm-p">
            <div>
              <h4>{t`future-academy.fap-title4`}</h4>
              <div className="fap-grid-p">
                <StaticImage
                  class="fap-p-image"
                  src="../../../images/future-academy/forbot-fa.png"
                  alt="Forbot"
                  placeholder="none"
                  loading="lazy"
                />
                <StaticImage
                  class="fap-p-image"
                  src="../../../images/future-academy/botland-fa.png"
                  alt="Botland"
                  placeholder="none"
                  loading="lazy"
                />
                {/* <StaticImage
                  class="fap-p-image"
                  src="../../../images/future-academy/uw-fa.png"
                  alt="Circle right"
                  placeholder="none"
                  loading="lazy"
                /> */}
              </div>
            </div>
            <div>
              <h4>{t`future-academy.fap-title5`}</h4>
              <div className="fap-grid-p">
                <StaticImage
                  class="fap-p-image"
                  src="../../../images/future-academy/google-fa.png"
                  alt="Google"
                  placeholder="none"
                  loading="lazy"
                />
                <StaticImage
                  class="fap-p-image"
                  src="../../../images/future-academy/codenauts-fa.png"
                  alt="Codenauts"
                  placeholder="none"
                  loading="lazy"
                />
                <StaticImage
                  class="fap-p-image"
                  src="../../../images/future-academy/inkubator-fa.png"
                  alt="UW Inkubator"
                  placeholder="none"
                  loading="lazy"
                />
                <StaticImage
                  class="fap-p-image up-image"
                  src="../../../images/future-academy/up-fa.png"
                  alt="Urząd Patentowy"
                  placeholder="none"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          {/* <h4>{t`future-academy.fap-title6`}</h4>
          <div className="fap-grid-pe">
            <StaticImage
              class="fap-pe-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-pe-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-pe-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-pe-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-pe-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              class="fap-pe-image"
              src="../../../images/future-academy/uw-fa.png"
              alt="Circle right"
              placeholder="none"
              loading="lazy"
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default FutureAcademyPartners;

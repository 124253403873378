import React from 'react';
import './future-academy.css';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ogImage from '../../images/seo/SEO_future_academy.png';
import FutureAcademyHeader from './components/future-academy-header';
import WhatIsFutureAcademy from './components/what-is-future-academy';
import FutureAcademyWorkshops from './components/future-academy-workshops';
import YouKnowWhat from './components/you-know-what';
import WhatStandsOut from './components/what-stands-out';
import Edition2024 from './components/edition-2024';
import FutureAcademyAgenda from './components/future-academy-agenda';
import FutureAcademyJurors from './components/future-academy-jurors';
import EditionIsOver from './components/edition-is-over';
import FutureAcademyPartners from './components/future-academy-partners';

const FutureAcademy = () => {
  const { t } = useTranslation();

  return (
    <Layout withHeader={false}>
      <Seo
        title={t`mentoring.page-title`}
        description={t`mentoring.page-description`}
        ogImage={ogImage}
      />
      <div className="future-academy-ctn">
        <FutureAcademyHeader />
        <WhatIsFutureAcademy />
        <FutureAcademyWorkshops />
        <YouKnowWhat />
        <div id="whatStandsOut">
          <WhatStandsOut />
        </div>
        <Edition2024 />
        <FutureAcademyAgenda />
        <FutureAcademyJurors />
        <EditionIsOver />
        <FutureAcademyPartners />
      </div>
    </Layout>
  );
};

export default FutureAcademy;

import React from 'react';
import '../styles/you-know-what.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const YouKnowWhat = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="circles">
        <StaticImage
          className="ykw-circle-right"
          src="../../../images/future-academy/circle-right-ykw.png"
          alt="Circle right"
          placeholder="none"
          loading="lazy"
        />
        <StaticImage
          className="ykw-circle-left"
          src="../../../images/future-academy/circle-left-ykw.png"
          alt="Circle left"
          placeholder="none"
          loading="lazy"
        />
        <div className="ykw-ctn">
          <div className="container">
            <h3>{t`future-academy.ykw-title`}</h3>
            <h2>{t`future-academy.ykw-title2`}</h2>
            <div className="ykw-grid">
              <div>
                <p className="p-body-light">
                  {t`future-academy.ykw-p1`}
                  <b className="p-body-bold">{t`future-academy.ykw-p2`}</b>
                  {t`future-academy.ykw-p3`}
                </p>
              </div>
              <div>
                <p className="p-body-light">
                  {t`future-academy.ykw-p4`}
                  <b className="p-body-bold">{t`future-academy.ykw-p5`}</b>
                  {t`future-academy.ykw-p6`}
                </p>
              </div>
              <div>
                <p className="p-body-light">
                  {t`future-academy.ykw-p7`}
                  <b className="p-body-bold">{t`future-academy.ykw-p8`}</b>
                  {t`future-academy.ykw-p9`}
                </p>
              </div>
            </div>
            <p className="p-gus">{t`future-academy.ykw-p10`}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default YouKnowWhat;

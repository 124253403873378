import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const Jurors = {
  pl: [
    {
      name: 'Krzysztof Zachwieja',
      bio: 'Wiceprezes Fundacji Innovations Hub, wcześniej Dyrektor Finansowy, gdzie od początku istnienia rozwijał flagowy program mentoringowy. Doświadczenie zawodowe zdobywał w międzynarodowych firmach doradczych - Boston Consulting Group, Deloitte oraz w firmie Venture Building The Heart. Student ostatniego roku CEMS Master in International Management w Szkole Głównej Handlowej i na Uniwersytecie Bocconi.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Tomek-Zachwieja.JPG"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Krzysztof Grochot',
      bio: 'Absolwent Międzywydziałowych Studiów Matematyczno-Przyrodniczych na Wydziale Fizyki, Astronomii i Informatyki Stosowanej Uniwersytetu Jagiellońskiego w Krakowie. W 2023 roku obronił doktorat w dyscyplinie nauki fizyczne. Jego naukowe zainteresowania skupiają się wokół nanoelektroniki i elektroniki spinowej. Po godzinach zajmuje się działalnością popularyzatorską prowadząc liczne warsztaty i wykłady dla dzieci i młodzieży. Prywatnie interesuje się programowaniem, nowymi technologiami i rozwiązaniami IoT.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Krzysztof-Grochot.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Sławomir Ziętek',
      bio: 'Absolwent kierunku Automatyka i Robotyka na Wydziale Inżynierii Mechanicznej i Robotyki Akademii Górniczo-Hutniczej w Krakowie. Po ukończeniu studiów magisterskich rozpoczął studia doktoranckie na kierunku elektronika, które ukończył w 2017 roku broniąc z wyróżnieniem pracę doktorską pod tytułem "Spin Diode Effect in metallic multilayers and multiferroic heterostructures". Jego zainteresowania naukowe związane są z nanoelektroniką, magnetyzmem oraz fizyką zarówno pod kątem eksperymentu jak i tworzenia modeli matematycznych zjawisk fizycznych.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Slawomir-Zietek.png"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Wojciech Roszczynialski',
      bio: 'Specjalista transferu technologii, wspólnik w firmie doradczej, doktor nauk technicznych. W swojej pracy zajmuje się procesem komercjalizacji wyników badań naukowych w realiach akademickich, inkubacją nowych przedsięwzięć gospodarczych, oraz szeroko rozumianą współpracą nauki z przemysłem. Od ponad dwóch dekad związany z Akademią Górniczo-Hutniczą im. Stanisława Staszica w Krakowie.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Wojciech-Roszczynialski.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Oliwia Nieckarz',
      bio: 'Studentka zarządzania zmianą społeczną, specjalizująca się w design thinking i kierowaniu zespołami. Zdobywała wiedzę międzynarodowo, m.in. w Korei Południowej, koncentrując się na różnorodności oraz integracji. Ma doświadczenie w koordynowaniu projektów w stowarzyszeniach studenckich i badaniach o tematyce feministycznej.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Oliwia-Nieckarz.png"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Miłosz Blasiak',
      bio: 'Doświadczonym inżynier systemów informatycznych, współtwórca systemów cyfryzujących i automatyzujących działania firm z sektora bankowego oraz budowlanego. Współzałożyciel Codenauts, członek Innovations Hub Foundation, zapewniający wsparcie z zakresu oceny technicznej i merytorycznej rozwiązań IT start-up’ów.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Miłosz-Blasiak.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Hubert Nowak',
      bio: 'Student informatyki na AGH. Członek samorządu studentów AGH UST. Czynnie działa na rzecz młodzieży i rozwijania przedsiębiorczości między innymi w Fundacji Instytut Europejski. Pasjonat sportu, zawodnik drużyny wioślarskiej.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Hubert-Nowak.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Piotr Kaźmierczak',
      bio: 'Doktor fizyki, obecnie koordynuje Makerspace@UW, przestrzeń kreatywną dla studentów Uniwersytetu Warszawskiego. Pasjonuje go prowadzenie różnorodnych zajęć dla uczniów każdego wieku. Specjalizuje się głównie w naukach ścisłych, takich jak matematyka i fizyka, prowadząc zajęcia z robotyki, elektroniki, projektowania 3D oraz DIY.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Piotr-Kaźmierczak.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Kacper Raciborski',
      bio: 'Założyciel i prezes Fundacji Innovations Hub, której misją jest transformacja polskiej sceny inkubacyjnej, edukacja z zakresu transferu technologii, rozwijania startupów i przedsiębiorczości, a także wdrażanie innowacyjnych pomysłów młodych na rynek. Z wykształcenia magister mechanik, inżynier fizyk i astronom.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Kacper-Raciborski.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Jan Dziedzic',
      bio: 'Student astronomii na Wydziale Fizyki Uniwersytetu Warszawskiego, który zajmuje się drukiem 3D od ponad 8 lat. Swojego doświadczenia w tej dziedzinie używał m.in przy takich projektach jak University of Warsaw Rover Team oraz w drużynie Warsaw MIMotaurs startującej w konkursie MBZIRC.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Jan-Dziedzic.png"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Paweł Szczypkowski',
      bio: 'Doktorant Wydziału Fizyki UW. Na co dzień zajmuje się pokonywaniem ograniczeń w optyce i przekazywaniem swojej wiedzy fizycznej innym. Pasjonat utrudniania sobie życia przez nadmierne myślenie. Szczęśliwy mąż i fan podróży - szczególnie tych sportowych (zima - narty, lato - kitesurfing). Jego marzeniem jest zrewolucjonizować sposób edukacji.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Paweł-Szczypkowski.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Krystian Garland',
      bio: 'Ukończył studia Ekonomii w specjalizacji Finansów i Rachunkowości na Politechnice Warszawskiej. Od dziecka miał smykałkę do IT i zawodowo zajmuje się wsparciem IT w firmie Dentons, gdzie pomaga użytkownikom w ich codziennych problemach z komputerami.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Krystian-Garland.png"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Andrzej Budzynski',
      bio: 'Andrzej jest wieloletnim pracownikiem IT, który zdobył swoje doświadczenie pracując na różnych stanowiskach. Pracował on dla polskich i zagranicznych firm o globalnym zasięgu. Od kilku lat zawodowo zajmuje się on zagadnieniami oraz szkoleniami z cyberbezpieczeństwa.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Andrzej-Budzynski.JPG"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Koło Naukowe Robocik',
      bio: 'Zespół ambitnych, młodych pasjonatów skupiających się na konstruowaniu zdalnie sterowanych pojazdów podwodnych (ROV) oraz autonomicznych (AUV). Prowadzą liczne szkolenia z zakresu robotyki podwodnej czy druku 3D, czym inspirują kolejne pokolenia do odkrywania naukowych ścieżek. Mimo młodego wieku mogą pochwalić się bogatym doświadczeniem, interdyscyplinarną wiedzą oraz sukcesami na różnych płaszczyznach.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Robocik.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Natalia Bürger',
      bio: 'Koło Naukowe Materiałów Kompozytowych KOMPOZYTYWNI realizuje projekty, skupiając się na użyciu materiałów kompozytowych. Zajmują się testowaniem różnych metod wytwarzania elementów z kompozytów. Wykorzystują do tego między innymi formy drukowane za pomocą drukarek 3D. Poza kompozytami łączą w swoich projektach m.in elektronikę czy odlewnictwo.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Natalia-(kompozytywni).jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Karolina Zalepa',
      bio: 'Koło Naukowe Materiałów Kompozytowych KOMPOZYTYWNI realizuje projekty, skupiając się na użyciu materiałów kompozytowych. Zajmują się testowaniem różnych metod wytwarzania elementów z kompozytów. Wykorzystują do tego między innymi formy drukowane za pomocą drukarek 3D. Poza kompozytami łączą w swoich projektach m.in elektronikę czy odlewnictwo.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Karolina-(kompozytywni).jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Małgorzata Majta',
      bio: 'Koło Naukowe Materiałów Kompozytowych KOMPOZYTYWNI realizuje projekty, skupiając się na użyciu materiałów kompozytowych. Zajmują się testowaniem różnych metod wytwarzania elementów z kompozytów. Wykorzystują do tego między innymi formy drukowane za pomocą drukarek 3D. Poza kompozytami łączą w swoich projektach m.in elektronikę czy odlewnictwo.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Gosia-(kompozytywni).jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Mikołaj Wolny',
      bio: 'Mikołaj zaczął programować, tworząc tracker nawyków. Później odkrył, że może pomagać ludziom rozwiązywać problemy poprzez pisanie programów. Z sukcesem przekształcił to w pracę i założył firmę szkolącą duże firmy w inteligentnym wykorzystaniu Chat GPT. Jego warsztaty podkreślają, że każdy, kto ma minimalne umiejętności techniczne, pracowitość i empatię, może tworzyć wartościowe rzeczy.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Mikołaj-Wolny.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Maja Szachniewicz',
      bio: 'Maja studiuje bioinformatykę we Wrocławiu. Na zajęciach programuje i bada życie burzliwie toczące się w każdym z nas. Między innymi odczytuje i modyfikuje w Pythonie sekwencje DNA albo przeszukuje zbiory biologicznych danych, pod kątem zależności między nimi. Prywatnie Maja tańczy taniec irlandzki oraz szydełkuje.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Maja-Szachniewicz.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Piotr Gątkowski',
      bio: 'Od roku rozwija się w kierunku ekonomiczno-analitycznym na SGH, oraz robi to co kocha -  pracuje na rzecz innych ludzi. Współtworzy fundację "Chodźże Seniorze", zajmującą się aktywizacją osób starszych. Realizował liczne projekty m.in w ramach "Zwolnionych z Teorii" oraz aktywnie działa w organizacjach na swojej uczelni.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Piotr-Gątkowski.jpg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    },
    {
      name: 'Oskar Formella',
      bio: 'Student kierunku Finanse i Rachunkowość na SGH. Chłopak zakochany w swojej dziewczynie i najmłodsza osoba z licencją maklera papierów wartościowych w historii. Na codzień pracuje w branży fuzji i przejęć, a jego głównymi zainteresowaniami są piłka nożna, musicale, siłownia oraz jego ukochana.',
      img: (
        <StaticImage
          className="faj-juror"
          src="../../../../images/future-academy/jurors/Oskar-Formella.jpeg"
          alt="Juror"
          placeholder="none"
          loading="lazy"
        />
      )
    }
  ],
  en: []
};

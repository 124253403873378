import React from 'react';
import '../styles/future-academy-workshops.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const FutureAcademyWorkshops = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="faw-background">
        <div className="container faw-ctn">
          <div className="faw-empty"></div>
          <div className="faw-text-ctn">
            <h4>{t`future-academy.faw-title`}</h4>
            <p className="p-body">{t`future-academy.faw-p`}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FutureAcademyWorkshops;
